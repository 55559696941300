let key = {};
const mode = process.env.REACT_APP_EXEC
if (process.env.REACT_APP_MODE === "Live") {
  console.log("Set Live Config");
  const API_URL = "https://botapi.arbitribe.com:2053";
  key = {
    API_URL: `${API_URL}`,
    FRONT_URL: "https://bot.arbitribe.com/",
    ADMIN_URL: "https://botcontrols.arbitribe.com",
    getGeoInfo: "https://ipapi.co/json/",
    mode,
    BITMART_API_URL: '',
    BITMART_ADMIN_URL: '',
  };
} else if (process.env.REACT_APP_MODE === "salesDemo") {
  console.log("Set salesDemo Config");
  const API_URL = "https://bottest.wearedev.team:2052";

  key = {
    API_URL: `${API_URL}`,
    FRONT_URL: "https://bottest.wearedev.team",
    ADMIN_URL: "https://dopamine-bot-arbitribe-demo.pages.dev",
    getGeoInfo: "https://ipapi.co/json/",
    mode,
    BITMART_API_URL: '',
    BITMART_ADMIN_URL: '',
  };
}else if (process.env.REACT_APP_MODE === "stage") {
  console.log("Set Stage Config");
  const API_URL = "https://botv2api.wearedev.team:2053";

  key = {
    API_URL: `${API_URL}`,
    FRONT_URL: "https://botv2.wearedev.team",
    ADMIN_URL: "https://bot-v2-controls.pages.dev",
    getGeoInfo: "https://ipapi.co/json/",
    mode,
    BITMART_API_URL: '',
    BITMART_ADMIN_URL: '',
  };
} else if (process.env.REACT_APP_MODE === "demo") {
  console.log("Set Demo Config");
  const API_URL = "https://arbitribeapi.wealwin.com";

  key = {
    API_URL: `${API_URL}`,
    FRONT_URL: "https://arbitribe.wealwin.com/",
    ADMIN_URL: "https://triangular-arbitribe-controls.pages.dev",
    getGeoInfo: "https://ipapi.co/json/",
    mode,
    BITMART_API_URL: '',
    BITMART_ADMIN_URL: '',
  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://localhost";
  key = {
    API_URL: `${API_URL}:2063`,
    ADMIN_URL: "http://localhost:3021",
    getGeoInfo: "https://ipapi.co/json/",
    mode,
    BITMART_API_URL: `${API_URL}:2077`,
    BITMART_ADMIN_URL: "http://localhost:3077",
  };
}

export default key;
